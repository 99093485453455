import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss']
})
export class IconComponent implements OnInit {
  @Input() iconType:string = null;
  @Input() iconLogo:string = `<i class="fas fa-folder-open"></i>`;
  @Input() iconTitle:string = `dossier sans nom`;
  @Input() zoom:number = 1;

  @Output() openWindowEvent:EventEmitter<any> = new EventEmitter();

  iconSelected:boolean = false;
  infos:any = null;

  constructor() { }

  ngOnInit() {
  }

  toggleSelectIcon(){
    this.iconSelected = !this.iconSelected;
  }

  openWindow(infos){
    this.openWindowEvent.emit(infos)
  }

}
