import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';

@Component({
  selector: 'window-component',
  templateUrl: './window.component.html',
  styleUrls: ['./window.component.scss']
})
export class WindowComponent implements OnInit, AfterViewInit {
  @Input() windowTitle:string = "";
  @Input() windowType:string = "";
  @Input() windowIcon:string = "";
  @Input() windowId:string = "";

  @Output() closeWindowEvent:EventEmitter<any> = new EventEmitter();
  @Output() focusEvent:EventEmitter<any> = new EventEmitter();



  windowWidth:string='700px';
  windowHeight:string='700px';
  @Input() windowTop:number=50;
  @Input() windowLeft:number=50;
  @Input() windowFocus:number;
  windowLeftString:string;
  windowTopString:string;

  constructor() { }

  ngOnInit() {
    this.windowLeftString=`${this.windowLeft}px`;
    this.windowTopString=`${this.windowTop}px`;
  }

  ngAfterViewInit(){

  }

  closeWindow(){
    this.closeWindowEvent.emit(this.windowId);
  }

  focus(){
    this.focusEvent.emit(this.windowId);
  }

//   dragMoveListener (event) {
//     var target = event.target,
//         // keep the dragged position in the data-x/data-y attributes
//         x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx,
//         y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy;

//     // translate the element
//     target.style.webkitTransform =
//     target.style.transform =
//       'translate(' + x + 'px, ' + y + 'px)';

//     // update the posiion attributes
//     target.setAttribute('data-x', x);
//     target.setAttribute('data-y', y);
//   }

//     function getMinWinSize (){
//       var leftSideWidth = document.getElementById('left-side').style.width;
//       var rightSideWidth = document.getElementById('right-side').style.width;
//       console.log(leftSideWidth)
//       return leftSideWidth;
//     }

//     var sidebar = document.getElementById('sidebar1');

//   var movable = document.getElementById('win1');

// interact(sidebar).resizable({
//     // resize from all edges and corners
//     edges: { left: true, right: true, bottom: false, top: false },

//     // keep the edges inside the parent
//     // restrictEdges: {
//     //   outer: 'parent',
//     //   endOnly: true,
//     // },

//     // minimum size
//     restrictSize: {
//       min: { width: 50, height:100},
//     },

//   }).on('resizemove', function (event) {
//     var target = event.target,
//         x = (parseFloat(target.getAttribute('data-x')) || 0),
//         y = (parseFloat(target.getAttribute('data-y')) || 0);

//     // update the element's style
//     target.style.width  = event.rect.width + 'px';
//     target.style.height = event.rect.height + 'px';

//     // translate when resizing from top or left edges
//     x += event.deltaRect.left;
//     y += event.deltaRect.top;

//     target.style.webkitTransform = target.style.transform =
//         'translate(' + x + 'px,' + y + 'px)';

//     target.setAttribute('data-x', x);
//     target.setAttribute('data-y', y);
//   });

//   interact(movable)
//   .draggable({
//     onmove: dragMoveListener
//   })
//   .resizable({
//     // resize from all edges and corners
//     edges: { left: true, right: true, bottom: true, top: true },

//     // keep the edges inside the parent
//     // restrictEdges: {
//     //   outer: 'parent',
//     //   endOnly: true,
//     // },

//     // minimum size
//     restrictSize: {
//       min: { width: 175, height: 32 },
//     },

//   })
//   .on('resizemove', function (event) {
//     var target = event.target,
//         x = (parseFloat(target.getAttribute('data-x')) || 0),
//         y = (parseFloat(target.getAttribute('data-y')) || 0);

//     // update the element's style
//     target.style.width  = event.rect.width + 'px';
//     target.style.height = event.rect.height + 'px';

//     // translate when resizing from top or left edges
//     x += event.deltaRect.left;
//     y += event.deltaRect.top;

//     target.style.webkitTransform = target.style.transform =
//         'translate(' + x + 'px,' + y + 'px)';

//     target.setAttribute('data-x', x);
//     target.setAttribute('data-y', y);
//     //target.textContent = Math.round(event.rect.width) + '\u00D7' + Math.round(event.rect.height);
//   });

}
