import { Component, OnInit } from '@angular/core';
import { v4 } from 'uuid';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  windowList:{
    title:string,
    type:string,
    icon:string,
    id:string,
    top:number,
    left:number,
    focus:number}[] = []

    ngOnInit(){

    }

    closeWindow(windowId){
      this.windowList.splice(this.windowList.findIndex(window => window.id === windowId),1);
    }

    focusEvent(windowId){
      this.windowList.sort((a,b) => a.focus - b.focus);
      this.resetFocus();
      const targetWindow = this.windowList.find(window=> window.id === windowId);

      targetWindow.focus = this.windowList[this.windowList.length - 1].focus + 1

    }

    resetFocus(){
      this.windowList.forEach((window,index)=>{
        window.focus = index + 1;
      })
    }

    openWindow(windowInfos){
      const position = this.checkIfWindowThere(50,50);
      this.windowList.push({
        title:``,
        type:"finder",
        icon:"<i class='fas fa-image'></i>",
        id: v4(),
        top: position.y,
        left: position.x,
        focus:this.getMaxFocus() + 1
      })
    }

    getMaxFocus(){
      let maxFocus = 1;
      
      if (this.windowList.length){
        this.windowList.sort((a,b) => a.focus - b.focus);
        maxFocus = this.windowList[this.windowList.length - 1].focus
      }
      return maxFocus;
    }

    checkIfWindowThere(x, y){
      const currentWindow = this.windowList.find(window => window.left === x || window.left === y);

      if (currentWindow){
        return this.checkIfWindowThere(currentWindow.left + 25, currentWindow.top + 25);
      } else {
        return ({x,y})
      }
    }
}
